const React = require("react")
const { BowlineProvider } = require("@64labs/bowline-design-system")
const theme = require("./bowline.config")

require("focus-visible")
require("typeface-open-sans")

// pre-imported icons for SVG sprite generation/usage
require("@64labs/bowline-design-system/icons/linkedin.svg")
require("@64labs/bowline-design-system/icons/external-link.svg")
require("@64labs/bowline-design-system/icons/chevron-left.svg")
require("@64labs/bowline-design-system/icons/chevron-right.svg")
require("@64labs/bowline-design-system/icons/grid.svg")
require("@64labs/bowline-design-system/icons/menu.svg")
require("@64labs/bowline-design-system/icons/x.svg")
require("@64labs/bowline-design-system/icons/info.svg")
require("@64labs/bowline-design-system/icons/check-circle.svg")

exports.wrapRootElement = ({ element }) => {
  return <BowlineProvider value={theme}>{element}</BowlineProvider>
}
