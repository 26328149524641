import React, { useState, useEffect } from "react"
import {
  Box,
  Text,
  Columns,
  Column,
  Button,
  ContentBlock,
  Stack,
  Inline,
  Icon,
  Divider,
  ScrollBox,
} from "@64labs/bowline-design-system"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./index.css"
import "../svg/icon-chat.svg"
import "../svg/icon-bubbles.svg"
import "../svg/icon-e-flask.svg"
import "../svg/icon-chart.svg"
import "../svg/icon-design.svg"
import "../svg/icon-gears.svg"
import { useSpring, animated } from "react-spring"

const HeroImage = ({ currentSlide, item, index }) => {
  const style = useSpring({
    opacity: currentSlide === index ? 1 : 0,
    config: { duration: 500 },
  })
  return (
    <animated.div style={style}>
      <Box
        position={index !== currentSlide && "absolute"}
        as={Link}
        aria-label={item.title}
        to={item.heroUrl}
      >
        <Box display={["block", "block", "none"]}>
          <Image
            loading="eager"
            fadeIn={false}
            alt={item.mobileImage.description}
            fluid={{
              ...item.mobileImage.fluid,
              aspectRatio: 450 / 404,
              media: "(max-width: 767px)",
            }}
          />
        </Box>
        <Box display={["none", "none", "block"]}>
          <Image
            loading="eager"
            fadeIn={false}
            alt={item.desktopImage.description}
            fluid={{ ...item.desktopImage.fluid, media: "(min-width: 768px)" }}
          />
        </Box>
      </Box>
    </animated.div>
  )
}

const IndexPage = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0)
  const [slideInterval, setSlideInterval] = useState(null)

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(currentSlide =>
        currentSlide === data.heroes.item.length - 1 ? 0 : currentSlide + 1
      )
    }, 5000)
    setSlideInterval(interval)
    return () => clearInterval(interval)
  }, [data.heroes.item.length])

  return (
    <Layout>
      <SEO title="Mobile and Web Solutions" />

      <Box
        position="relative"
        style={{
          zIndex: 100,
          background: data.heroes.item[currentSlide].backgroundColor,
        }}
      >
        <ContentBlock position="relative" paddingX={["none", "gutter"]}>
          {data.heroes.item?.map((_item, index) => {
            return (
              <HeroImage
                currentSlide={currentSlide}
                item={_item}
                index={index}
              />
            )
          })}
         {data.heroes.item?.length > 1 && 
           <Box
              display="flex"
              justify="center"
              position="absolute"
              className="c-heroes__dot-group"
            >
              {data.heroes.item.map((_item, index) => (
                <Button
                  style={{ width: 48, height: 48 }}
                  weight="weak"
                  display="flex"
                  justify="center"
                  align="flex-end"
                  onClick={() => {
                    setCurrentSlide(index)
                    clearInterval(slideInterval)
                  }}
                >
                  <Box
                    aria-label="carousel dot"
                    padding="none"
                    className={`hero__dot ${
                      currentSlide === index ? "hero__dot--selected" : ""
                    }`}
                    
                  />
                </Button>
              ))}
            </Box>
          }
        </ContentBlock>
      </Box>
      {/* <Box
        style={{
          height: 17,
          background: `url(${data.featureBarImage.childImageSharp.fluid.src})`,
        }}
        marginBottom={["none", "xsmall"]}
      /> */}
      <Box paddingY="xxsmall">
        <ScrollBox scrollX>
          <Inline
            wrap={false}
            paddingX="gutter"
            space={["gutter", "gutter", "xlarge"]}
            justify={["flex-start", "flex-start", "flex-start", "center"]}
          >
            {data.featuredClients.item.map(
              ({ clientName, clientLogo }, index) => {
                const style = {}
                const name = clientName
                  .replace(/ /g, "-")
                  .replace(/'/g, "")
                  .toLowerCase()

                // Pandora logo sits lower than the rest - remove once fixed
                if (name === "pandora") {
                  style.paddingBottom = 8
                }

                return (
                  <Text
                    className="home__featured-client-image"
                    as={Link}
                    to={`/work/${name}`}
                    key={`home-featured-client-${index}`}
                    aria-label={name}
                    baseline={false}
                    display="flex"
                    style={{
                      width: "100%",
                      height: "100%",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Box padding="xxsmall" style={{ width: 142 }}>
                    <Image
                        alt={clientLogo.description}
                        imgStyle={style}
                        fluid={clientLogo.fluid}
                      />
                    </Box>
                  </Text>
                )
              }
            )}
          </Inline>
        </ScrollBox>
      </Box>

      <Box
        background="white"
        style={{
          background:
            "-webkit-linear-gradient(top, #F5F5F5, #f5f5f5 70%, #fff)",
        }}
      >
        <ContentBlock
          paddingBottom={["large", "large", "xlarge"]}
          paddingTop={["gutter", "gutter", "xlarge"]}
        >
          <Columns
            cols={[1, 1, 3, 3]}
            colGap={["none", "large", "xxlarge"]}
            rowGap="large"
          >
            <Stack align="center" space="small">
              <Stack space="small" align="center">
                <Icon name="icon-chat" size="xxlarge" />
                <Text as="h3" heading size="large" tone="brandAccent">
                  Expertise
                </Text>
                <Text align="center">
                  With 10 full composable builds under our belt, no one in the 
                  SFCC universe has as much experience of successful composable 
                  launches as 64labs.
                </Text>
              </Stack>
              <Button as={Link} to="/about" size="small" weight="strong">
                why 64labs
              </Button>
            </Stack>
            <Stack align="center" space="small">
              <Stack space="small" align="center">
                <Icon name="icon-bubbles" size="xxlarge" />
                <Text as="h3" heading size="large" tone="brandAccent">
                  Acceleration
                </Text>
                <Text align="center">
                  Acceleration is not just velocity. It means starting a 
                  composable journey with a well crafted path to launch 
                  guided by a partner who knows the terrain.
                </Text>
              </Stack>
              <Button as={Link} to="/services" size="small" weight="strong">
                how we work
              </Button>
            </Stack>
            <Stack align="center" space="small">
              <Stack space="small" align="center">
                <Icon name="icon-e-flask" size="xxlarge" />
                <Text as="h3" heading size="large" tone="brandAccent">
                  Agility
                </Text>
                <Text align="center">
                  64labs is built to work efficiently with other teams and 
                  partners. We balance application of our skills with effective 
                  knowledge transfer to others.
                </Text>
              </Stack>
              <Button size="small" as={Link} to="/work" weight="strong">
                our projects
              </Button>
            </Stack>
          </Columns>
        </ContentBlock>
      </Box>

      <Box background="subtleLight" position="relative" overflow="hidden">
        <Box
          position="absolute"
          size="xlarge"
          background="white"
          style={{
            top: -34,
            left: "50%",
            transformOrigin: "top left",
            transform: "rotate(45deg)",
          }}
        />
        <Box>
          <ContentBlock paddingY="xxlarge">
            <Stack align="center" space="gutter">
              <Text as="h1" tone="brand" heading size="xlarge" align="center">
                You need a partner who has done this before
              </Text>
              <Text align="center" size="large" style={{maxWidth:"900px"}}>
                Your site is not a training exercise. If your production replatform 
                is the first time your partner or developer team has ever built a 
                composable site then it will take a long time and you will make critical 
                mistakes. These are hard projects to do well. And easy projects to do badly.
              </Text>
            </Stack>
          </ContentBlock>
          <ContentBlock>
            <Divider />
          </ContentBlock>
          <ContentBlock paddingY="large">
            <Columns cols={4}>
              <Column span={[4, 4, 2, 1]}>
                <Stack space="small">
                  <Inline justify="center" space="xsmall">
                    <Icon name="icon-chart" />
                    <Text
                      as="h2"
                      heading
                      weight="medium"
                      tone="brandAccentAccessible"
                    >
                      Plan
                    </Text>
                  </Inline>
                  <Text align="center">
                    All 64labs projects are planned and architected on the back 
                    of an accelerator and project process developed over the course
                    of 10 live builds in two years
                  </Text>
                </Stack>
              </Column>
              <Column span={[4, 4, 2, 1]}>
                <Stack space="small">
                  <Inline justify="center" space="xsmall">
                    <Icon name="icon-design" />
                    <Text
                      as="h2"
                      heading
                      weight="medium"
                      tone="brandAccentAccessible"
                    >
                      Implement
                    </Text>
                  </Inline>
                  <Text align="center">
                    Because we start on the front foot, our projects take only 
                    16-24 weeks. A flexible engagement model allows us to draw 
                    your team in according to skill
                  </Text>
                </Stack>
              </Column>
              <Column span={[4, 4, 2, 1]}>
                <Stack space="small">
                  <Inline justify="center" space="xsmall">
                    <Icon name="icon-gears" />
                    <Text
                      as="h2"
                      heading
                      weight="medium"
                      tone="brandAccentAccessible"
                    >
                      Train
                    </Text>
                  </Inline>
                  <Text align="center">
                    We hand our projects over with pride - in the build, in the 
                    documents, in the transfer of knowledge. There is nothing 
                    your team can’t handle from here.
                  </Text>
                </Stack>
              </Column>
              <Column span={[4, 4, 2, 1]}>
                <Stack space="small">
                  <Inline justify="center" space="xsmall">
                    <Icon name="icon-chat" />
                    <Text
                      as="h3"
                      heading
                      weight="medium"
                      tone="brandAccentAccessible"
                    >
                      Support
                    </Text>
                  </Inline>
                  <Text align="center">
                    We tailor flexible siupport packages for clients whose docket 
                    has not shrunk and whose team wants to keep the momentum of 
                    changed going.
                  </Text>
                </Stack>
              </Column>
            </Columns>
          </ContentBlock>
        </Box>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query {
    heroes: contentfulList(title: { eq: "Home Hero Order" }) {
      item {
        ... on ContentfulHomeHero {
          title
          backgroundColor
          heroUrl
          desktopImage {
            description
            fluid(maxWidth: 1200, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          mobileImage {
            description
            fluid(maxWidth: 450, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    featuredClients: contentfulList(title: { eq: "Featured Clients Order" }) {
      id
      item {
        ... on ContentfulClientWork {
          id
          clientName
          clientLogo {
            description
            fluid(
              maxWidth: 200
              maxHeight: 100
              quality: 100
              resizingBehavior: PAD
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    featureBarImage: file(relativePath: { eq: "feature_bar_bckgrd.png" }) {
      childImageSharp {
        fluid(maxWidth: 4) {
          src
        }
      }
    }
  }
`

export default IndexPage
