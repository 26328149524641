import React from "react"
import { Box, Text, Stack } from "@64labs/bowline-design-system"

const StyleGuide = () => {
  return (
    <Stack padding="large">
      <Box style={{ background: "salmon" }}>
        <Text heading size="xlarge">
          ldgldgldgldgldg
        </Text>
      </Box>
      <Box style={{ background: "salmon" }}>
        <Text heading size="large">
          ldgldgldgldgldg
        </Text>
      </Box>
      <Box style={{ background: "salmon" }}>
        <Text heading size="standard">
          ldgldgldgldgldg
        </Text>
      </Box>
      <Box style={{ background: "salmon" }}>
        <Text heading size="small">
          ldgldgldgldgldg
        </Text>
      </Box>
      <Box style={{ background: "salmon" }}>
        <Text heading size="xsmall">
          ldgldgldgldgldg
        </Text>
      </Box>
      <Box style={{ background: "salmon" }}>
        <Text size="xlarge">ldgldgldgldgldg</Text>
      </Box>
      <Box style={{ background: "salmon" }}>
        <Text size="large">ldgldgldgldgldg</Text>
      </Box>
      <Box style={{ background: "salmon" }}>
        <Text size="standard">ldgldgldgldgldg</Text>
      </Box>
      <Box style={{ background: "salmon" }}>
        <Text size="small">ldgldgldgldgldg</Text>
      </Box>
      <Box style={{ background: "salmon" }}>
        <Text size="xsmall">ldgldgldgldgldg</Text>
      </Box>
    </Stack>
  )
}

export default StyleGuide
