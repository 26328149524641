import React, { useState } from "react"
import cx from "classnames"
import { Box, Text, Stack } from "@64labs/bowline-design-system"

const ServiceCard = ({ icon, title, text }) => {
  const [activeServices, setActiveServices] = useState(false)

  return (
    <Box
      onClick={() => setActiveServices(!activeServices)}
      background="white"
      padding={["small", "small", "medium"]}
      display="flex"
      alignItems={[
        activeServices ? "flex-start" : "center",
        activeServices ? "flex-start" : "center",
        "flex-start",
      ]}
      style={{ borderRadius: 8 }}
    >
      <Stack
        paddingY={["xsmall", "xsmall", "none"]}
        className={cx({ "service-card__text--active": activeServices })}
      >
        <Text as="h3" heading tone="brand">
          {title}
        </Text>
        <Text
          display={[
            activeServices ? "block" : "none",
            activeServices ? "block" : "none",
            "block",
          ]}
        >
          {text}
        </Text>
      </Stack>
    </Box>
  )
}

export default ServiceCard
