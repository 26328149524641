import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import { Box, ContentBlock, Button, Text } from "@64labs/bowline-design-system"
import BrandLogo from "./brand-logo"
import "./header.css"

const Header = ({ isMobileNavOpen, onMobileNavClick }) => {
  const location = useLocation()

  return (
    <Box as="header" background="brandAccent" className="app-header">
      <ContentBlock paddingX={["none", "none", "none", "gutter"]}>
        <Box
          display="flex"
          align="stretch"
          justify="space-between"
          className="app-header__contain"
        >
          <Box display={["block", "block", "block", "none"]}>
            <Button
              aria-label="mobile nav"
              icon={isMobileNavOpen ? "x" : "menu"}
              iconSize="gutter"
              weight="weak"
              boxShadow="none"
              onClick={() => onMobileNavClick(!isMobileNavOpen)}
            />
          </Box>

          <Box className="logo-wrapper">
            <BrandLogo />
          </Box>

          <Box
            size="xlarge"
            background="white"
            display={["block", "block", "block", "none"]}
            aria-hidden
            style={{ visibility: "hidden" }}
          />

          <Box display={["none", "none", "none", "flex"]}>
            {["about", "services", "work", "contact"].map((item, i) => (
              <React.Fragment key={item}>
                <Box
                  aria-hidden
                  style={{ width: 1 }}
                  background="brandAccent"
                />
                <Text
                  className={cx("header-nav-link", {
                    "header-nav-link--active": location.pathname.includes(
                      `/${item}`
                    ),
                  })}
                  as={Link}
                  to={`/${item}/`}
                  baseline={false}
                  display="flex"
                  alignItems="center"
                  paddingX="large"
                  position="relative"
                >
                  {item}
                  {location.pathname.includes(item) && (
                    <Box
                      size="small"
                      background="subtleLight"
                      position="absolute"
                      style={{
                        top: 95,
                        left: "50%",
                        transform: "rotate(45deg) translateX(-50%)",
                      }}
                    />
                  )}
                </Text>
              </React.Fragment>
            ))}
            <Box aria-hidden style={{ width: 1 }} background="brandAccent" />
          </Box>
        </Box>
      </ContentBlock>
    </Box>
  )
}

export default Header
