import React from "react"
import { Box, Text, Button, Stack } from "@64labs/bowline-design-system"
import "../../pages/about.css"

const ProfileCard = ({ name, title, linkedInHref, children }) => {
  return (
    <Stack align="flex-start" justify="space-between" space="medium">
      <Box>
        <Stack space="gutter">
          <Stack space="xsmall">
            <Text as="h3" size="small" tone="brandAccent" heading>
              {name}
            </Text>
            <Text size="small">{title}</Text>
          </Stack>
          {children}
        </Stack>
      </Box>
      <Button
        as="a"
        href={linkedInHref}
        background="brandLight"
        size="small"
        icon="linkedin"
      >
        View Profile
      </Button>
    </Stack>
  )
}

export default ProfileCard
