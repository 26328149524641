import React, { useState } from "react"
import { createPortal } from "react-dom"
import { useTransition, animated } from "react-spring"
import { Button, Box } from "@64labs/bowline-design-system"
import "./modal.css"

export const useModal = (initialOpen = false) => {
  const [isOpen, setIsOpen] = useState(initialOpen)

  const openModal = () => {
    setIsOpen(true)
  }

  const closeModal = () => {
    setIsOpen(false)
  }

  return {
    isOpen,
    open: openModal,
    close: closeModal,
  }
}

const Modal = ({ isOpen, close, className, children, contentClassName }) => {
  const transitions = useTransition(isOpen, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: { mass: 1, tension: 210, friction: 20 },
  })

  if (typeof window === "undefined") {
    return null
  }

  return createPortal(
    transitions.map(
      ({ item, key, props }) =>
        item && (
          <animated.div
            style={props}
            key={key}
            className={`u-modal ${className}`}
          >
            <Box className="u-modal__scrim" onClick={close} />
            <Box className={`u-modal__content ${contentClassName}`}>
              <Button
                onClick={close}
                variant="icon"
                className="u-modal__close-btn"
                aria-label="Close"
                icon="x"
                iconSize="large"
              />
              {children}
            </Box>
          </animated.div>
        )
    ),
    document.getElementById("___gatsby")
  )
}

export default Modal
