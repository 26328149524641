import React from "react"
import { Link } from "gatsby"
import {
  Columns,
  Text,
  ContentBlock,
  Button,
  Stack,
} from "@64labs/bowline-design-system"
import "./page-feature.css"
import Image from "gatsby-image"

const PageFeature = ({
  fluid,
  title,
  buttonText,
  children,
  buttonLink,
  className,
  ...props
}) => {
  return (
    <ContentBlock width="large" paddingY={["gutter", "medium", "large"]}>
      <Columns cols={[1, 1, 1, 2]} style={{ alignItems: "center" }} gap="large">
        <Image
          loading="eager"
          fadeIn={false}
          fluid={fluid}
          style={{ borderRadius: "8px" }}
          backgroundColor="#EEE"
        />

        <Stack space="small">
          <Text as="h2" size="xlarge" heading tone="brandAccent">
            {title}
          </Text>
          <Stack space="large" align="flex-start">
            {children}
            <Button as={Link} to={buttonLink} weight="strong">
              {buttonText}
            </Button>
          </Stack>
        </Stack>
      </Columns>
    </ContentBlock>
  )
}

export default PageFeature
