import React from "react"
import { string, object } from "yup"
import { Field, Form, Formik } from "formik"
import {
  Input,
  Columns,
  Column,
  Button,
  Textarea,
  Stack,
} from "@64labs/bowline-design-system"
import { navigate } from "gatsby"

const formSchema = object().shape({
  name: string().required("This field is required."),
  email: string()
    .email("Please enter a valid email.")
    .required("This field is required."),
  company: string(),
  phone: string(),
  description: string().required("This field is required."),
})

const ContactForm = () => {
  const onSubmit = async values => {
    if (process.env.NODE_ENV !== "production") {
      return navigate("/contact/thanks")
    }

    const formData = Object.keys(values)
      .map(key => {
        return `${encodeURIComponent(key)}=${encodeURIComponent(values[key])}`
      })
      .join("&")

    try {
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: formData,
      })
      navigate("/contact/thanks")
    } catch (e) {
      // TODO: set some error status
      console.log(e)
    }
  }

  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        company: "",
        phone: "",
        description: "",
        "form-name": "contact",
      }}
      validationSchema={formSchema}
      onSubmit={onSubmit}
    >
      {formProps => (
        <Form name="contact" method="post">
          <Stack>
            <Columns cols={[1, 1, 2]}>
              <Field
                name="name"
                render={({ field, form }) => {
                  const isInvalid = form.touched.name && form.errors.name
                  return (
                    <Input
                      {...field}
                      outline
                      message={isInvalid && form.errors.name}
                      tone={isInvalid && "critical"}
                      label="Name *"
                      className="contact-form__input"
                    />
                  )
                }}
              />
              <Field
                type="email"
                name="email"
                render={({ field, form }) => {
                  const isInvalid = form.touched.email && form.errors.email
                  return (
                    <Input
                      {...field}
                      outline
                      message={isInvalid && form.errors.email}
                      tone={isInvalid && "critical"}
                      type="email"
                      label="Email *"
                    />
                  )
                }}
              />
              <Field
                name="company"
                render={({ field, form }) => {
                  const isInvalid = form.touched.company && form.errors.company
                  return (
                    <Input
                      {...field}
                      outline
                      message={isInvalid && form.errors.company}
                      tone={isInvalid && "critical"}
                      label="Company"
                    />
                  )
                }}
              />
              <Field
                name="phone"
                render={({ field, form }) => {
                  const isInvalid = form.touched.phone && form.errors.phone
                  return (
                    <Input
                      {...field}
                      outline
                      message={isInvalid && form.errors.phone}
                      tone={isInvalid && "critical"}
                      label="Phone"
                    />
                  )
                }}
              />
              <Column span={[1, 1, 2]}>
                <Field
                  name="description"
                  render={({ field, form }) => {
                    const isInvalid =
                      form.touched.description && form.errors.description
                    return (
                      <Textarea
                        {...field}
                        message={isInvalid && form.errors.description}
                        tone={isInvalid && "critical"}
                        label="What's the story? *"
                        style={{ maxWidth: "100%", minWidth: "100%" }}
                      />
                    )
                  }}
                />
              </Column>
            </Columns>
            <Button
              type="submit"
              style={{ width: "auto" }}
              background="brandAccent"
            >
              Submit
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  )
}

export default ContactForm
