import React from "react"
import { Link } from "gatsby"
import { Box, Icon } from "@64labs/bowline-design-system"
import brandIcon from "../svg/brand-logo.svg"

const BrandLogo = () => {
  return (
    <React.Fragment>
      <Link
        to="/"
        className="logo-wrapper__logo-link"
        aria-current="page"
        aria-label="Home"
      >
        <Icon name={brandIcon.id} />
      </Link>
      <Box className="logo-effects">
        <svg width="141px" height="57px" viewBox="0 0 141 57">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <circle fill="#FFFFFF" cx="51" cy="27" r="27"></circle>
            <circle fill="#FFFFFF" cx="78.5" cy="36.5" r="13.5"></circle>
            <circle
              fill="#FFFFFF"
              cx="100.5"
              cy="19.5"
              r="10.5"
              fillOpacity={0.8}
            ></circle>
            <circle
              fill="#FFFFFF"
              cx="113"
              cy="37"
              r="6"
              fillOpacity={0.6}
            ></circle>
            <circle
              fill="#FFFFFF"
              cx="130"
              cy="54"
              r="3"
              fillOpacity={0.6}
            ></circle>
            <circle
              fill="#FFFFFF"
              cx="102"
              cy="50"
              r="1"
              fillOpacity={0.5}
            ></circle>
            <circle
              fill="#FFFFFF"
              cx="124"
              cy="21"
              r="4"
              fillOpacity={0.3}
            ></circle>
            <circle
              fill="#FFFFFF"
              cx="18"
              cy="32"
              r="4"
              fillOpacity={0.5}
            ></circle>
            <circle
              fill="#FFFFFF"
              cx="12"
              cy="19"
              r="6"
              fillOpacity={0.4}
            ></circle>
            <circle
              fill="#FFFFFF"
              cx="2"
              cy="24"
              r="2"
              fillOpacity={0.2}
            ></circle>
            <circle
              fill="#FFFFFF"
              cx="133"
              cy="32"
              r="2"
              fillOpacity={0.2}
            ></circle>
            <circle
              fill="#FFFFFF"
              cx="140"
              cy="24"
              r="1"
              fillOpacity={0.2}
            ></circle>
          </g>
        </svg>
      </Box>
    </React.Fragment>
  )
}

export default BrandLogo
