import React from "react"
import { graphql } from "gatsby"
import {
  Box,
  Columns,
  Text,
  ContentBlock,
  Stack,
  Divider,
} from "@64labs/bowline-design-system"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useStaticQuery } from "gatsby"
import PageFeature from "../components/page-feature"
import "./about.css"
import ProfileCard from "../components/about/profile-card"

const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query aboutFeature {
      file(relativePath: { eq: "img-about.png" }) {
        childImageSharp {
          fluid(maxWidth: 972, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      leadership: contentfulList(title: { eq: "Leadership Order" }) {
        title
        item {
          ... on ContentfulLeadership {
            name
            title
            linkedInUrl
            bio {
              bio
            }
          }
        }
      }
    }
  `)

  const leadership = data && data.leadership && data.leadership.item
  return (
    <Layout showPageHeader={true} pageHeaderText="Why 64labs">
      <SEO
        title="About Us"
        description="64labs partners with big and small companies to help get the best and most cost effective solution to web challenges."
      />
      <PageFeature
        fluid={data.file.childImageSharp.fluid}
        title="Experience counts"
        buttonText="64labs projects"
        buttonLink="/work"
      >
        <Text className="page-feature__paragraph">
          64labs is not the biggest integrator in the Salesforce universe. But we 
          have a particular set of skills. 64labs helped build PWA Kit. We built 
          the first PWA Kit accelerator. We launched the first three Composable 
          Storefront sites. We have 8 more sites in production than our nearest 
          rival. That expertise means lower risk, shorter timelines and happier 
          clients.
        </Text>
      </PageFeature>

      <Box
        background="subtleLight"
        paddingY="large"
        position="relative"
        overflow="hidden"
      >
        <Box
          size="xlarge"
          background="white"
          position="absolute"
          style={{
            top: -8,
            left: "50%",
            transform: "rotate(45deg) translateX(-50%)",
          }}
        />
        <ContentBlock width="large" paddingTop="small">
          <Box>
            <Stack space="large">
              <Stack space="small">
                <Text as="h2" heading size="large" tone="brandAccent">
                  A proven pathway to getting your composable project done
                </Text>
                <Text style={{maxWidth: "900px"}}>
                  The key to a successful client engagement is transparency. 64labs’ 
                  project process is easy to understand, has specific complete deliverables 
                  and is flexible enough to suit a range of client sizes, locations, and 
                  product types. We will strongly recommend a headless CMS as part of the 
                  purchase. And you should seriously consider headless Search as a fast 
                  follow. But our transparency goes further than most. We are the only 
                  company willing to tell you up front what the cost and timeline and ROI 
                  should be on a composable storefront project.
                  {" "}
                  <strong>
                    Here goes...
                  </strong>
                </Text>
              </Stack>

              <Divider style={{ borderTop: "1px solid #ddd" }} />

              <Columns cols={[1, 1, 3]} gap="large">
                <Stack space="small">
                  <Text as="h3" tone="brand" heading>
                    16-26 weeks
                  </Text>
                  <Text>
                    We operate in three fixed phases and one flexible phase. Sprint0 
                    (phase 1) is 4 weeks and Scopotype (phase 2) is 8 weeks. Launch 
                    is four weeks. There is one flex phase called Build to Launch 
                    which can last between 4 and 14 weeks. Ask our clients. No 64labs 
                    project has taken us longer.
                  </Text>
                </Stack>
                <Stack space="small">
                  <Text as="h3" tone="brand" heading>
                    $600k to $850k
                  </Text>
                  <Text>
                    Three of our phases are fixed fee. Sprint0 ($185k) is a practical 
                    hands-on discovery and prototyping phase. Scopotype ($385k) is 
                    when we build 70% of your site. The Build to Launch phase varies 
                    between 2 and 7 two-week sprints at roughly $40-$60k a sprint, 
                    depending on your resource needs. Launch ($65k) gets your site live.
                  </Text>
                </Stack>
                <Stack space="small">
                  <Text as="h3" tone="brand" heading>
                    Up to 40% bounce-rate boost
                  </Text>
                  <Text>
                    Our first A-B tested launch – Site Genesis pitted against 
                    Composable Storefront - was on Sweaty Betty. That project paid 
                    for itself before we turned off the A-B test after two months 
                    launched into peak season. It was clear how composable helped. 
                    Bounce was down. Engagement metrics were up. Add to cart was 
                    up. Overall conversion was up.
                  </Text>
                </Stack>
              </Columns>
            </Stack>
          </Box>
        </ContentBlock>
      </Box>

      <ContentBlock width="large" paddingY="large">
        <Stack space="medium" style={{ maxWidth: "900px" }}>
          <Text as="h2" heading tone="brandAccent" size="large">
            The 64labs Process
          </Text>

          <Stack space="small">
            <Text as="h3" tone="brand" heading>
              01 / Sprint0
            </Text>
            <Text>
              Sprint0 is a four-week phase. We connect our SFCC accelerator to see 
              what works out of the box and identify the quantity and difficulty of 
              back-end work required. By the end of Sprint0 you will normally have 
              an end-to-end functional PWA. We design and architect the complete CMS 
              with your merchants. We break the project down into component-based 
              user stories working closely with your team to enter Scopotype phase 
              with a clear view of the road ahead.
            </Text>
          </Stack>
          <Stack space="small">
            <Text as="h3" tone="brand" heading>
              02 / Scopotype
            </Text>
            <Text>
              Scopotype is an eight‒week phase in which 64labs completes about 70％ 
              of the work of the project. The pace is driven by 64labs and occurs in 
              four 2‒week sprints with full QA, demonstrations of completed work and 
              releases every sprint. Our aim during this phase is to build out all 
              the critical foundational work, implement and migrate a complete CMS, 
              and arrive at the Build‒to‒Launch phase with a clear, scope of what 
              remains to be done and who will do it.
            </Text>
          </Stack>
          <Stack space="small">
            <Text as="h3" tone="brand" heading>
              03 / Build2Launch
            </Text>
            <Text>
              BTL is a variable length phase (between 4 and 12 weeks) where 64labs 
              finishes the project while transferring knowledge and responsibility 
              more towards an internal team or existing partner. The core work of 
              the site is complete but some key tasks remain. Internal developers 
              can be effective during this phase and can gain experience in the c
              odebase, taking ownership of the project. At the end of BTL, the project 
              should be complete and ready for UAT.
            </Text>
          </Stack>
          <Stack space="small">
            <Text as="h3" tone="brand" heading>
              04 / Launch
            </Text>
            <Text>
              Launch is a four-week phase that can take place immediately after BTL 
              or some weeks later. This phase is designed to take advantage of 64labs’s 
              extensive experience launching composable sites and anticipating the 
              specific challenges of a Go Live. Launch phase can include a partial 
              launch, a split launch or regional launch. Launch phase starts two weeks 
              prior to intended Go Live  and continues for two weeks afterwards.
            </Text>
          </Stack>
          <Stack space="small">
            <Text as="h3" tone="brand" heading>
              05 / Momentum
            </Text>
            <Text>
              Momentum is exactly what it sounds like. All of us have experienced that 
              deflation at the end of a project when everyone disappears to the next 
              big thing. But the battle to be a composable ecommerce powerhouse does 
              not end at launch: it begins. This is where 64labs can help. A Momentum 
              engagement allows your team to draw up a six-month roadmap of improvements 
              to the site and have a lightweight 64labs team of 2-4 people lead and 
              deliver that work.
            </Text>
          </Stack>
          <Stack space="small">
            <Text as="h3" tone="brand" heading>
              06 / Support
            </Text>
            <Text>
              Sometimes you just need to buy some time to recruit for your internal 
              team. Sometimes your incumbent partner needs time to find real composable 
              experts that can help you. Whatever the reason, 64labs is happy to provide 
              trained project-hardened engineers to clients after projects even where 
              there is not the roadmap in place to commission a Momentum phase. When you 
              just need experienced help keeping the ship afloat in composable, 64labs 
              is there for you.
            </Text>
          </Stack>
        </Stack>
      </ContentBlock>

      <ContentBlock width="large" paddingY="large">
        <Stack space="medium">
          <Text as="h2" heading tone="brandAccent" size="large">
            Our leadership team
          </Text>

          <Columns cols={[1, 1, 2]} rowGap="large">
            {leadership &&
              leadership.map((leader, idx) => {
                return (
                  <>
                    <ProfileCard
                      key={idx}
                      name={leader.name}
                      title={leader.title}
                      linkedInHref={leader.linkedInUrl}
                    >
                      <Text size="small">{leader.bio && leader.bio.bio}</Text>
                    </ProfileCard>
                    {idx !== leadership.length - 1 && (
                      <Divider display={["block", "block", "none"]} />
                    )}
                  </>
                )
              })}
          </Columns>
        </Stack>
      </ContentBlock>
    </Layout>
  )
}

export default AboutPage
