import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import { Text, Button, Stack } from "@64labs/bowline-design-system"

const Sidebar = () => {
  const data = useStaticQuery(graphql`
    query {
      sidebarImage: file(relativePath: { eq: "img-contact.png" }) {
        childImageSharp {
          fluid(maxWidth: 650) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)
  return (
    <Stack padding="gutter" background="subtleLight">
      <Image
        // TODO: integrate borderRadius values into bowline
        style={{ borderRadius: 3 }}
        fluid={data.sidebarImage.childImageSharp.fluid}
      />
      <Stack space="xsmall" align="flex-start">
        <Text tone="brandAccent" size="xlarge">
          Address
        </Text>
        <Text size="small">3507 E. Frontage Rd</Text>
        <Text size="small">Suite 100</Text>
        <Text size="small">Tampa, FL 33607</Text>
        <Button
          size="small"
          background="brandLight"
          as="a"
          href="https://www.google.com/maps/place/3507+E+Frontage+Rd+%23100,+Tampa,+FL+33607"
          target="_blank"
          rel="noopener noreferrer"
        >
          map/directions
        </Button>
      </Stack>
      <Stack space="xsmall" align="flex-start">
        <Text tone="brandAccent" size="xlarge">
          Email
        </Text>
        <Button
          background="brandLight"
          size="small"
          as="a"
          href="mailto:getstarted@64labs.com?subject=64Labs Contact"
        >
          get started
        </Button>
      </Stack>
    </Stack>
  )
}

export default Sidebar
