import React from "react"
import { MDXProvider } from "@mdx-js/react"
import { MDXRenderer } from "gatsby-plugin-mdx"
import { Box, Text } from "@64labs/bowline-design-system"

const components = {
  h1: ({ children }) => (
    <Box marginBottom="small" marginTop="medium">
      <Text as="h1" heading size="xlarge">
        {children}
      </Text>
    </Box>
  ),
  h2: ({ children }) => (
    <Box marginBottom="small" marginTop="medium">
      <Text as="h2" heading size="large" tone="brandAccent">
        {children}
      </Text>
    </Box>
  ),
  h3: ({ children }) => (
    <Box marginBottom="small" marginTop="medium">
      <Text as="h3" heading size="standard">
        {children}
      </Text>
    </Box>
  ),
  p: ({ children }) => (
    <Box marginBottom="small">
      <Text>{children}</Text>
    </Box>
  ),
}

const MDXComponent = ({ children, ...props }) => {
  return (
    <MDXProvider components={components}>
      <MDXRenderer {...props}>{children}</MDXRenderer>
    </MDXProvider>
  )
}

export default MDXComponent
