import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql, Link } from "gatsby"
import { animated, useSpring, useTransition } from "react-spring"
import "pure-react-carousel/dist/react-carousel.es.css"
import Header from "./header"
import {
  Box,
  Button,
  Columns,
  Inline,
  Text,
  ContentBlock,
  Divider,
  Stack,
  useTheme,
} from "@64labs/bowline-design-system"
import "./layout.css"
import { useLocation } from "@reach/router"

const MobileNavigationMenu = ({ isOpen }) => {
  const style = useSpring({
    from: {
      height: 0,
      opacity: 0,
      display: "none",
      position: "relative",
      zIndex: 106,
    },
    to: async next => {
      if (isOpen) {
        await next({ display: "block" })
        await next({ height: 350 })
        await next({ opacity: 1 })
      } else {
        await next({ opacity: 0 })
        await next({ height: 0 })
        await next({ display: "none" })
      }
    },
    config: { duration: 200 },
  })
  const location = useLocation()
  return (
    <animated.div style={style}>
      <ul className="mobile-nav">
        <li>
          <Button
            paddingX="gutter"
            size="large"
            to="/about"
            background={location.pathname.includes("/about") && "subtleLight"}
            as={Link}
            weight="link"
            tone="neutral"
            iconRight="chevron-right"
            innerJustify
          >
            <strong style={{ fontWeight: 600 }}>About</strong>
          </Button>
          <Divider tone="subtleLight" />
        </li>

        <li>
          <Button
            paddingX="gutter"
            size="large"
            to="/services"
            background={
              location.pathname.includes("/services") && "subtleLight"
            }
            as={Link}
            weight="link"
            tone="neutral"
            iconRight="chevron-right"
            innerJustify
          >
            <strong style={{ fontWeight: 600 }}>Services</strong>
          </Button>
          <Divider tone="subtleLight" />
        </li>

        <li>
          <Button
            paddingX="gutter"
            size="large"
            to="/work"
            background={location.pathname.includes("/work") && "subtleLight"}
            as={Link}
            weight="link"
            tone="neutral"
            iconRight="chevron-right"
            innerJustify
          >
            <strong style={{ fontWeight: 600 }}>Work</strong>
          </Button>
          <Divider tone="subtleLight" />
        </li>

        <li>
          <Button
            paddingX="gutter"
            size="large"
            to="/contact/"
            background={
              location.pathname.includes("/contact/") && "subtleLight"
            }
            as={Link}
            weight="link"
            tone="neutral"
            iconRight="chevron-right"
            innerJustify
          >
            <strong style={{ fontWeight: 600 }}>Contact</strong>
          </Button>
          <Divider tone="subtleLight" />
        </li>
      </ul>
      <ContentBlock background="white" paddingY="gutter">
        <Stack space="gutter">
          <Stack space="smallish">
            <Text heading tone="brandAccent">
              64Labs
            </Text>
            <Text>3507 E. Frontage Rd, Suite 100</Text>
            <Text>Tampa, FL 33607</Text>
          </Stack>
          <Columns cols={2}>
            <Button
              as="a"
              href="https://www.google.com/maps/place/3507+E+Frontage+Rd+%23100,+Tampa,+FL+33607"
              weight="strong"
              paddingX="none"
            >
              map/directions
            </Button>
          </Columns>
        </Stack>
      </ContentBlock>
    </animated.div>
  )
}

const PageHeader = ({ pageHeaderText, nextClient }) => {
  return (
    <Box
      display="flex"
      justify="space-between"
      align="center"
      className="page-header"
    >
      <Text
        flexGrow={5}
        as="h1"
        tone="brand"
        size="xlarge"
        heading
        baseline={false}
      >
        {pageHeaderText}
      </Text>
      {nextClient && (
        <Box display={["none", "none", "block"]} flexGrow={1}>
          <Button
            as={Link}
            to={`/work/${nextClient
              .replace(/ /g, "-")
              .replace(/'/g, "")
              .toLowerCase()}`}
            size="small"
            iconRight="chevron-right"
          >
            Next - {nextClient}
          </Button>
        </Box>
      )}
    </Box>
  )
}

const NavigationMask = ({ show, setIsMobileNavOpen }) => {
  const transitions = useTransition(show, null, {
    from: { position: "absolute", opacity: 0 },
    enter: { opacity: 1, top: 0, bottom: 0, left: 0, right: 0 },
    leave: { opacity: 0 },
    config: { duration: 200 },
  })
  return transitions.map(
    ({ item, key, props }) =>
      item && (
        <animated.div key={key} style={props}>
          <Box
            role="button"
            onClick={() => setIsMobileNavOpen(false)}
            position="absolute"
            background="brand"
            style={{
              opacity: 0.6,
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              zIndex: 105,
            }}
          />
        </animated.div>
      )
  )
}

const Layout = ({
  pageHeaderText,
  showPageHeader,
  nextClient,
  clientWorkPage,
  children,
}) => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false)
  const [displayClientWorkNav, setDisplayClientWorkNav] = useState(false)
  useEffect(() => {
    if (clientWorkPage) {
      const pageHeader = document.getElementById("client-work-page-header")

      // offsetParent is null if the element is set to display:none which this element is when you are above 767px viewport
      if (pageHeader && pageHeader.offsetParent) {
        const pageHeaderOffset =
          pageHeader.offsetHeight + pageHeader.offsetParent.offsetTop

        const onChange = () => {
          if (window.scrollY >= pageHeaderOffset && window.innerWidth < 768) {
            setDisplayClientWorkNav(true)
          } else {
            setDisplayClientWorkNav(false)
          }
        }
        window.addEventListener("scroll", onChange)
        return () => window.removeEventListener("scroll", onChange)
      }
    }
  }, [])
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const clientWorkMobileNavStyles = useSpring({
    from: {
      height: 0,
      opacity: 0,
      display: "none",
      position: "fixed",
      top: 0,
      zIndex: 101,
      boxShadow: "0 3px 5px 0 rgba(69,85,96, 0.3)",
      width: "100%",
    },
    to: async next => {
      if (displayClientWorkNav) {
        await next({ display: "block" })
        await next({ height: 72 })
        await next({ opacity: 1 })
      } else {
        await next({ opacity: 0 })
        await next({ height: 0 })
        await next({ display: "none" })
      }
    },
    config: { duration: 200 },
  })
  return (
    <>
      <Header
        isMobileNavOpen={isMobileNavOpen}
        onMobileNavClick={setIsMobileNavOpen}
        siteTitle={data.site.siteMetadata.title}
      />

      <Box
        aria-hidden
        paddingTop={["xxsmall", "xxsmall", "xxsmall", "small"]}
      ></Box>

      <MobileNavigationMenu isOpen={isMobileNavOpen} />

      <Box as="main" position="relative">
        <NavigationMask
          show={isMobileNavOpen}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        {showPageHeader && (
          <Box
            background="subtleLight"
            position="relative"
            style={{
              zIndex: 100,
              background:
                "-webkit-linear-gradient(top, #f5f5f5, #f5f5f5 70%, #eee)",
            }}
            id={clientWorkPage ? "client-work-page-header" : ""}
          >
            <ContentBlock width="large">
              <PageHeader
                nextClient={nextClient}
                pageHeaderText={pageHeaderText}
              />
            </ContentBlock>
          </Box>
        )}
        {clientWorkPage && (
          <animated.div style={clientWorkMobileNavStyles}>
            <Box
              paddingY="small"
              background="white"
              style={clientWorkMobileNavStyles}
            >
              <ContentBlock>
                <Inline space="xsmall">
                  <Button
                    style={{ paddingRight: 20 }}
                    size="small"
                    background="brandAccent"
                    as={Link}
                    to="/work"
                    icon="chevron-left"
                    className="layout__work-btn-mobile"
                  >
                    Work
                  </Button>
                  <Button
                    flexGrow={1}
                    as={Link}
                    size="small"
                    to={`/work/${nextClient
                      .replace(/ /g, "-")
                      .replace(/'/g, "")
                      .toLowerCase()}`}
                    iconRight="chevron-right"
                    className="layout__work-btn-mobile"
                  >
                    {nextClient}
                  </Button>
                </Inline>
              </ContentBlock>
            </Box>
          </animated.div>
        )}
        {children}
      </Box>
      <Box position="relative">
        <NavigationMask
          show={isMobileNavOpen}
          setIsMobileNavOpen={setIsMobileNavOpen}
        />
        <Box background="brandAccent" style={{ height: 20 }} aria-hidden />

        <Box
          as="footer"
          background="brand"
          paddingTop="gutter"
          paddingBottom="xxlarge"
        >
          <ContentBlock>
            <Text size="small" tone="subtleLight">
              © 64labs, all rights reserved.
            </Text>
          </ContentBlock>
        </Box>
      </Box>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
