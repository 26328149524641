module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"64labs","short_name":"64labs","start_url":"/","background_color":"#ffffff","theme_color":"#d60b85","display":"minimal-ui","icon":"src/images/favicon512.png","icons":[{"src":"/favicons/64_touch_icon_72.png","sizes":"72x72","type":"image/png"},{"src":"/favicons/64_touch_icon_114.png","sizes":"114x114","type":"image/png"},{"src":"/favicons/64_touch_icon_512.png","sizes":"512x512","type":"image/png"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/work*"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-42107151-1"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
