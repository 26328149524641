import React from "react"
import {
  Box,
  ContentBlock,
  Columns,
  Column,
  Text,
  Stack,
} from "@64labs/bowline-design-system"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import ContactForm from "../../components/contact/contact-form"
import Sidebar from "../../components/contact/sidebar"

const Contact = () => {
  return (
    <Layout showPageHeader pageHeaderText="Contact us today">
      <SEO
        title="Contact Us"
        description="Get in touch with 64labs to discuss your mobile and web challenges."
      />
      <Box paddingY={["gutter", "gutter", "large"]}>
        <ContentBlock>
          <Columns cols={[1, 1, 4]}>
            <Column span={[1, 1, 3]}>
              <Stack space="small" marginBottom="medium">
                <Text size="xlarge" tone="brandAccent" heading as="h2">
                  We can help
                </Text>
                <Text size="large">
                  We work alongside you to do great things. However big or small
                  your challenges are, we want to hear about them.{" "}
                  <b>Give us a shout.</b>
                </Text>
              </Stack>
              <ContactForm />
            </Column>

            <Sidebar />
          </Columns>
        </ContentBlock>
      </Box>
    </Layout>
  )
}

export default Contact
