import React, { useState, useEffect } from "react"
import { Link, graphql } from "gatsby"
import Image from "gatsby-image"
import {
  Box,
  Columns,
  Text,
  ContentBlock,
  Divider,
  Stack,
} from "@64labs/bowline-design-system"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "./work.css"

const WorkPage = ({ data }) => {
  const [projectOrder, setProjectOrder] = useState(data.clientList.item)
  const [currentFilter, setCurrentFilter] = useState("")

  useEffect(() => {
    if (window && window.location.hash) {
      setCurrentFilter(
        window.location.hash.replace("#", "").replace("%20", " ")
      )
    }
  }, [])

  useEffect(() => {
    if (currentFilter === "") {
      setProjectOrder(data.clientList.item)
    } else {
      const newArr = projectOrder.slice()
      newArr.sort((a, b) => {
        const clientWorkMatchA = data.allContentfulClientWork.edges.find(
          ({ node }) => node.clientName === a.clientName
        )
        const clientWorkMatchB = data.allContentfulClientWork.edges.find(
          ({ node }) => node.clientName === b.clientName
        )
        const aIncludesFilter = clientWorkMatchA.node.projectCategory.some(
          ({ categoryName }) => categoryName === currentFilter
        )
        const bIncludesFilter = clientWorkMatchB.node.projectCategory.some(
          ({ categoryName }) => categoryName === currentFilter
        )
        let returnVal = 0
        if (aIncludesFilter && !bIncludesFilter) {
          returnVal = -1
        } else if (bIncludesFilter && !aIncludesFilter) {
          returnVal = 1
        }
        return returnVal
      })
      setProjectOrder(newArr)
    }
  }, [currentFilter]) /* eslint-disable-line react-hooks/exhaustive-deps */

  return (
    <Layout showPageHeader pageHeaderText="Select client work">
      <SEO
        title="Working With Us"
        description="We build mobile and responsive websites in rapid time using a highly transparent and collaborative agile process."
      />

      <ContentBlock
        style={{ height: 80 }}
        align="center"
        display={["none", "none", "flex"]}
      >
        <Link onClick={() => setCurrentFilter("")} to="/work/">
          <Text
            tone={currentFilter === "" ? "brandAccent" : "neutral"}
            paddingRight="medium"
          >
            show all
          </Text>
        </Link>
        {data.categoryList.item.map(item => {
          return (
            <Link
              key={item.categoryName}
              onClick={() => setCurrentFilter(item.categoryName)}
              to={`/work/#${item.categoryName}`}
            >
              <Text
                tone={
                  currentFilter === item.categoryName
                    ? "brandAccent"
                    : "neutral"
                }
                paddingRight="medium"
              >
                {item.categoryName}
              </Text>
            </Link>
          )
        })}
      </ContentBlock>

      <Box background="subtleLight">
        <ContentBlock>
          <Columns
            gap={["xsmall", "small", "gutter"]}
            cols={[1, 2, 3, 3]}
            paddingY={["small", "medium"]}
          >
            {projectOrder.map(({ clientName }, index) => {
              let fade = false
              const path = `/work/${clientName
                .replace(/ /g, "-")
                .replace(/'/g, "")
                .toLowerCase()}`
              const clientWorkMatch = data.allContentfulClientWork.edges.find(
                ({ node }) => node.clientName === clientName
              )
              if (
                currentFilter !== "" &&
                clientWorkMatch.node.projectCategory &&
                !clientWorkMatch.node.projectCategory.some(
                  ({ categoryName }) => categoryName === currentFilter
                )
              ) {
                fade = true
              }
              return (
                <Box
                  className={`${fade ? "work__fade" : ""}`}
                  key={`work-page-item-${index}`}
                  background="white"
                >
                  <Link
                    aria-label={path}
                    to={`/${path}`}
                    className="work-page-item--zoomable"
                    style={{ display: "block" }}
                  >
                    <Box className="work-page-item__main-image">
                      {index < 2 ?
                        // For Performance reasons, load the first few images eagerly with no LQIP
                        // LCP Element may not be consistent across devices
                        <Image
                        loading="eager"
                        fadeIn={false}
                        alt={
                          clientWorkMatch.node.clientWorkMainImage.description
                        }
                        imgStyle={{ transition: "all 500ms ease-out" }}
                        backgroundColor="#ddd"
                        fluid={clientWorkMatch.node.clientWorkMainImage.fluid}
                        />
                      :
                        <Image
                        alt={
                          clientWorkMatch.node.clientWorkMainImage.description
                        }
                        imgStyle={{ transition: "all 500ms ease-out" }}
                        backgroundColor="#ddd"
                        fluid={clientWorkMatch.node.clientWorkMainImage.fluid}
                        />
                      }
                    </Box>
                    <Box
                      display="flex"
                      align="center"
                      justify="center"
                      paddingY="xsmall"
                      style={{ width: "100%" }}
                    >
                      <Image
                        alt={clientWorkMatch.node.clientLogo.description}
                        fluid={clientWorkMatch.node.clientLogo.fluid}
                        style={{ width: "100%", maxWidth: "50%" }}
                      />
                    </Box>
                  </Link>
                </Box>
              )
            })}
          </Columns>
        </ContentBlock>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query {
    allContentfulClientWork {
      edges {
        node {
          clientName
          projectCategory {
            categoryName
          }
          clientWorkMainImage {
            description
            fluid(maxWidth: 600) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          clientLogo {
            description
            fluid(
              maxWidth: 200
              maxHeight: 100
              quality: 100
              resizingBehavior: PAD
            ) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    categoryList: contentfulList(title: { eq: "Project Category List" }) {
      item {
        ... on ContentfulProjectCategory {
          categoryName
        }
      }
    }
    clientList: contentfulList(title: { eq: "Client Work Order" }) {
      item {
        ... on ContentfulClientWork {
          clientName
        }
      }
    }
  }
`

export default WorkPage
