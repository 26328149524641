import React from "react"
import { graphql } from "gatsby"
import Image from "gatsby-image"
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from "pure-react-carousel"
import {
  Box,
  Columns,
  Column,
  ContentBlock,
  Divider,
  Text,
  Button,
  Icon,
  Stack,
  Inline,
} from "@64labs/bowline-design-system"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MDX from "../components/mdx"
import Modal, { useModal } from "../components/modal"
import "./client-work.css"

const ClientWorkTemplate = ({ data, pageContext, ...props }) => {
  const modal = useModal()
  const {
    pageData,
    clientList,
    mobifyBanner,
    moovwebBanner,
    gatsbyBanner,
    nextjsBanner,
    sfccBanner,
  } = data
  const {
    url,
    clientDisplayUrl,
    platform,
    clientDetailImage,
    logo,
    projectCategory,
    clientWorkDescription,
    slideshowImages,
    clientName,
  } = pageData

  const _clientList = clientList.item
  const clientIndex = _clientList.indexOf(
    _clientList.find(_item => _item.clientName === clientName)
  )
  const nextClient =
    clientIndex === _clientList.length - 1
      ? _clientList[0].clientName
      : _clientList[clientIndex + 1].clientName

  return (
    <Layout
      clientWorkPage
      nextClient={nextClient}
      showPageHeader
      pageHeaderText={`Work - ${clientName}`}
    >
      <SEO
        title={clientName}
        description={`View case study for the ${clientName} mobile website.`}
      />

      <Box paddingY="gutter">
        <ContentBlock>
          <Columns rowGap="smallish" cols={12}>
            <Column span={[12, 12, 7]}>
              <Stack space="smallish">
                {clientDetailImage?.map(({ fluid, description }) => (
                  <Image
                    loading="eager"
                    fadeIn={false}
                    alt={description}
                    fluid={fluid}
                    backgroundColor="#eee"
                  />
                ))}
                {platform === "Mobify" ? (
                  <Box
                    as={"a"}
                    aria-label="Mobify"
                    href="https://www.mobify.com/"
                  >
                    <Image
                      fluid={mobifyBanner.childImageSharp.fluid}
                      backgroundColor="#eee"
                    />
                  </Box>
                ) : platform === "Moovweb" ? (
                  <Box
                    as={"a"}
                    aria-label="Moovweb"
                    href="https://www.moovweb.com/"
                  >
                    <Image
                      fluid={moovwebBanner.childImageSharp.fluid}
                      backgroundColor="#eee"
                    />
                  </Box>
                ) : platform === "Gatsby" ? (
                  <Box
                    as={"a"}
                    aria-label="Gatsby"
                    href="https://www.gatsbyjs.org/"
                  >
                    <Image
                      fluid={gatsbyBanner.childImageSharp.fluid}
                      backgroundColor="#eee"
                    />
                  </Box>
                ) : platform === "next-js" ? (
                  <Box
                    as={"a"}
                    aria-label="Nextjs"
                    href="https://nextjs.org/"
                  >
                    <Image
                      fluid={nextjsBanner.childImageSharp.fluid}
                      backgroundColor="#eee"
                    />
                  </Box>
                ) : platform === "composable-storefront" ? (
                  <Box
                    as={"a"}
                    aria-label="SFCC"
                    href="https://www.salesforce.com/commerce/b2c-ecommerce/"
                  >
                    <Image
                      fluid={sfccBanner.childImageSharp.fluid}
                      backgroundColor="#eee"
                    />
                  </Box>
                ) : null}
              </Stack>
            </Column>

            <Column span={[12, 12, 5]}>
              <Stack>
                <Box
                  background="subtleLight"
                  paddingTop={["xsmall", "xsmall", "gutter"]}
                  paddingBottom="gutter"
                  paddingX="gutter"
                >
                  {logo && (
                    <Box
                      style={{
                        maxWidth: 180,
                        height: 80,
                        margin: "0 auto",
                      }}
                    >
                      <Image alt={logo.description} fixed={logo.fixed} />
                    </Box>
                  )}

                  <Stack paddingTop={["xsmall", "xsmall", "gutter"]}>
                    <Divider style={{ borderColor: "#DDD" }} />

                    <Stack space="gutter" align="flex-start">
                      <Stack space="smallish">
                        <Inline space="xxsmall">
                          <Text size="small" weight="strong">
                            Project Type:
                          </Text>
                          <Text size="small">
                            {projectCategory?.map(
                              ({ categoryName }, index) =>
                                `${index !== 0 ? ", " : ""}${categoryName}`
                            )}
                          </Text>
                        </Inline>

                        <Inline space="xxsmall">
                          <Text size="small" weight="strong">
                            Client URL:{" "}
                          </Text>
                          <Text
                            size="small"
                            target="_blank"
                            rel="noopener noreferrer"
                            href={url}
                            as={"a"}
                            tone="brand"
                            block
                          >
                            {clientDisplayUrl}
                          </Text>
                        </Inline>
                      </Stack>
                      {slideshowImages?.length > 0 && (
                        <Button
                          onClick={modal.open}
                          icon="external-link"
                          weight="strong"
                          size="small"
                        >
                          view screens
                        </Button>
                      )}
                    </Stack>
                  </Stack>
                </Box>

                <Box>
                  <MDX>{clientWorkDescription.childMdx.body}</MDX>
                </Box>
              </Stack>
            </Column>
          </Columns>
        </ContentBlock>
      </Box>
      <Modal
        className="client-work__modal"
        contentClassName="client-work__modal-content"
        {...modal}
      >
        <Box style={{ height: "100vh" }} position="relative">
          <Box
            style={{ height: 50, right: 0, left: 0, zIndex: 1 }}
            background="brandAccent"
            position="absolute"
          />
          <CarouselProvider
            naturalSlideHeight={1000}
            naturalSlideWidth={300}
            totalSlides={slideshowImages?.length||0}
          >
            <Slider>
              {slideshowImages?.map(({ description, fluid }, index) => {
                return (
                  <Slide
                    key={`client-work-slide-${index}`}
                    style={{ background: "rgb(243, 243, 245)" }}
                  >
                    <Box
                      style={{
                        maxWidth: 600,
                        height: "100vh",
                        overflowY: "auto",
                        margin: "auto",
                        marginTop: 50,
                      }}
                    >
                      <Image alt={description} fluid={fluid} />
                    </Box>
                  </Slide>
                )
              })}
            </Slider>
            <Box
              display="flex"
              position="absolute"
              className="client-work__carousel-btn-container"
            >
              <ButtonBack className="client-work__carousel-btn">
                <Icon tone="white" size="large" name="chevron-left" />
              </ButtonBack>
              <ButtonNext className="client-work__carousel-btn">
                <Icon tone="white" size="large" name="chevron-right" />
              </ButtonNext>
            </Box>
          </CarouselProvider>
          <Box
            position="absolute"
            style={{ height: 50, bottom: 0, left: 0, right: 0 }}
            background="brandAccent"
          />
        </Box>
      </Modal>
    </Layout>
  )
}

export const query = graphql`
  query($clientName: String!) {
    pageData: contentfulClientWork(clientName: { eq: $clientName }) {
      id
      clientName
      url: clientWorkUrl
      clientDisplayUrl
      platform
      projectCategory {
        categoryName
      }
      clientWorkDescription {
        childMdx {
          body
        }
      }
      clientDetailImage {
        description
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      logo: clientLogo {
        description
        fixed(width: 180, height: 80, quality: 100, resizingBehavior: PAD) {
          ...GatsbyContentfulFixed_withWebp_noBase64
        }
      }
      slideshowImages: clientDetailScreens {
        description
        fluid(maxWidth: 600, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
    clientList: contentfulList(title: { eq: "Client Work Order" }) {
      item {
        ... on ContentfulClientWork {
          clientName
        }
      }
    }
    mobifyBanner: file(relativePath: { eq: "mobify-platform-tag.png" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    moovwebBanner: file(relativePath: { eq: "moov-platform-tag.png" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    gatsbyBanner: file(relativePath: { eq: "gatsby-platform-tag.png" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    nextjsBanner: file(relativePath: { eq: "nextjs-platform-tag.png" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    sfccBanner: file(relativePath: { eq: "sfcc-cs-platform-tag.png" }) {
      childImageSharp {
        fluid(maxWidth: 750, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default ClientWorkTemplate
