import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"
import {
  Box,
  Text,
  ContentBlock,
  Stack,
  Columns,
} from "@64labs/bowline-design-system"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageFeature from "../components/page-feature"
import ServiceCard from "../components/services/service-card"
import "./services.css"

const ServicePage = () => {
  const data = useStaticQuery(graphql`
    query servicesFeature {
      file(relativePath: { eq: "img-services.png" }) {
        childImageSharp {
          fluid(maxWidth: 972, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
      services: contentfulList(title: { eq: "Services Order" }) {
        title
        item {
          ... on ContentfulServices {
            id
            serviceName
            serviceDescription {
              serviceDescription
            }
          }
        }
      }
      partners: contentfulList(title: { eq: "Technology Partners Order" }) {
        title
        item {
          ... on ContentfulTechnologyPartners {
            id
            partnerName
            partnerUrl
            partnerLogo {
              description
              fluid(maxWidth: 233, quality: 100) {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout showPageHeader={true} pageHeaderText="Services we offer">
      <SEO
        title="Mobile and Web Services"
        description="64labs is an agile development company that gets mobile projects done quickly and efficiently."
      />
      <PageFeature
        fluid={data.file.childImageSharp.fluid}
        title="Experts in composable"
        buttonText="how can we help?"
        buttonLink="/contact/"
      >
        <Text className="page-feature__paragraph">
          Small team, large team, one site, multi-site. 64labs has been there, 
          done that and has ideas on how to do it better. You cannot put your 
          project in more experienced, more accountable hands. You will not 
          find a team out there with stronger commitment to delivering what 
          you need to a high standard, on time and on budget, whatever the 
          services you require.
        </Text>
      </PageFeature>
      <Box position="relative" background="subtleLight" overflow="hidden">
        <Box
          size="xlarge"
          background="white"
          position="absolute"
          style={{
            top: -36,
            left: "50%",
            transformOrigin: "top left",
            transform: "rotate(45deg)",
          }}
        />
        <ContentBlock paddingY="large">
          <Stack space="small">
            <Text as="h2" heading={true} size="large" tone="brandAccent">
              Our services
            </Text>
            <Columns
              cols={[1, 1, 1, 1]}
              gap={["xsmall", "xsmall", "xsmall", "small"]}
            >
              {data.services.item.map((item, i) => {
                return (
                  <ServiceCard
                    key={item.id}
                    title={item.serviceName}
                    text={item.serviceDescription.serviceDescription}
                  />
                )
              })}
            </Columns>
          </Stack>
        </ContentBlock>
      </Box>

      <ContentBlock>
        <Stack paddingY="large" space="small">
          <Text as="h2" size="xlarge" tone="brandAccent">
            Technology Partners
          </Text>
          <Text>
            64labs leverages industry leading software and infrastructure
            partners to deliver the right web solutions for our clients.
            Delivering these solutions require us to use flexible, scalable and
            dependable solutions.
            <strong> Some of our technology partners include:</strong>
          </Text>
        </Stack>
        <Columns cols={[1, 1, 3]} gap="xxsmall" marginBottom="medium">
          {data.partners.item.map((item, i) => {
            return (
              <Box
                as="a"
                key={item.id}
                href={item.partnerUrl}
                className="services__partner-logo"
                paddingX="small"
                paddingY="xxsmall"
                style={{ borderRadius: 8 }}
              >
                <Box style={{ maxWidth: 200, margin: "0 auto" }}>
                  <Image
                    alt={item.partnerLogo.description}
                    fluid={item.partnerLogo.fluid}
                  />
                </Box>
              </Box>
            )
          })}
        </Columns>
      </ContentBlock>
    </Layout>
  )
}

export default ServicePage
