import React from "react"
import { useStaticQuery, Link, graphql } from "gatsby"
import Image from "gatsby-image"
import Layout from "../components/layout"
import SEO from "../components/seo"
import {
  Box,
  Button,
  ContentBlock,
  Stack,
  Text,
} from "@64labs/bowline-design-system"

const NotFoundPage = () => {
  const data = useStaticQuery(graphql`
    query pageNotFound {
      file(relativePath: { eq: "img-404.png" }) {
        childImageSharp {
          fluid(maxWidth: 650, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Layout showPageHeader pageHeaderText="Uh oh!">
      <SEO title="404: Not found" />
      <ContentBlock width="large" paddingY="large" style={{ maxWidth: 650 }}>
        <Stack align="center">
          <Box alignSelf="stretch">
            <Image
              loading="eager"
              fadeIn={false}
              fluid={data.file.childImageSharp.fluid}
              style={{ borderRadius: "8px" }}
            />
          </Box>
          <Text size="large" align="center">
            We're sorry, but something unexpected happened.
          </Text>
          <Button as={Link} href="/" weight="weak" icon="chevron-left">
            Back to Homepage
          </Button>
        </Stack>
      </ContentBlock>
    </Layout>
  )
}

export default NotFoundPage
